<template>
  <div>

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <div class="h1">
        2024年国家级放射影像专业质控中心深圳工作会议
      </div>
      <section>
        <div class="div_p">
          为了规范放射诊断全流程质量管理，确保医疗安全，更好的推进深圳市影像同质化发展以及影像结果互通互认工作，由深圳市放射诊断质量控制中心主办、前海蛇口自贸区医院承办的“2024年国家级放射影像专业质控中心深圳工作会议”于2024年4月18日上午在前海蛇口自贸区医院1号楼6楼多功能厅召开。
        </div>
        <div>
          <img src="@/assets/SZ2024/1.png" alt="">
        </div>
        <div class="div_p">
          开幕式上，前海蛇口自贸区医院郭梅梅院长代表医院发表致辞，首先就嘉宾的远道而来表示欢迎，其次对三名工程依托团队表示衷心的感谢。
        </div>
        <div>
          <img src="@/assets/SZ2024/2.png" alt="">
        </div>
        <div class="div_p">
          来宾中国医学科学院北京协和医院、国家放射影像专业质控中心主任委员金征宇教授代表团队首先对此次到访受到的欢迎表示感谢，其次发表了演讲，表明质控工作是高质量发展的基础，指出国家放射影像专业质控中心建设及医疗质量控制指标进行部署的重要性。
        </div>
        <div>
          <img src="@/assets/SZ2024/3.png" alt="">
          <img src="@/assets/SZ2024/4.png" alt="">
        </div>
        <div class="div_p">
          会议期间，深圳市放射诊断质量控制中心主任、深圳市人民医院放射科主任龚静山教授进行了深圳市放射质控中心工作汇报；随后，与会专家们就放射质控危急值质控工作内涵、盆腔MRI检查质控、CTA的图像质量控制、放射影像学临床科研、能量CT助力下肢穿支动脉高质量成像、耐高压输液港作为CT增强检查对比剂注射通路的临床应用等课题展开授课及谈论。
        </div>
        <div>
          <img src="@/assets/SZ2024/5.png" alt="">
        </div>
        <div class="div_p">
          闭幕式上，前海蛇口自贸区医院医学影像科王静主任、中国医学科学院北京协和医院金征宇教授对演讲者和与会者表示感谢，表示本次会议对更好的推进深圳市影像同质化发展以及影像结果互通互认工作至关重要，并祝贺会议圆满落幕。
        </div>

      </section>
    </div>

  </div>
</template>

<script>
export default {
name:"SZ2024"
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>